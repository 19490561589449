$assetsPath: '../../../assets/';

@use 'sass:math';

@import 'common-variables';
@import 'react-slidedown';
@import 'fonts';
@import './node_modules/@avantia/style-guide/fonts.scss';

.fade.active:not(.show) {
  // cancel a conflict between react-bootstrap Tabs and bootstrap. Might be a versioning issue.
  opacity: 100;
}

* {
  @include font-body;
}

h1.salutation-header {
  padding-left: 1rem;
}

i.svg-icon {
  position: relative;
  top: -1px;
  width: 12px;
  & > svg {
    width: inherit;
    height: inherit;
  }
}

body.documents-section {
  $price-promise-card-bg-color: #e9773b;

  main {
    // border-left: 1px solid red;
    // border-right: 1px solid red;
    & > div.container {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .agg > #header {
    display: none;
  }

  color: #121212;
  background-color: $colorBodyBg;

  #root > div.App {
    margin-bottom: 50px;
    & main.container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .row label {
    font-weight: bold;
  }

  .td-btn {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  .btn {
    border-radius: $borderRadius;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: $standardPadding;
    font-size: 16px;
    margin-bottom: 10px;
    height: $buttonHeight;
  }

  .btn.btn-action {
    position: relative;
    box-shadow: none;
    & i {
      position: relative;
      margin-right: 7px;
    }
  }

  // By default the toggle button has margins and padding. We need to remove them just for this section.
  .av-toggle-button {
    display: inline-block;
    padding: 0 !important;

    & .btn {
      margin-bottom: 3px;
      & > span {
        & > p {
          font-size: 12px;
          margin: 2px 0 0 19px;
        }
      }

      &.active {
        & > span {
          & > p {
            margin: 2px 19px 0 0;
          }
        }
      }
    }

    & label.toggle-label {
      margin-top: 0;
      padding-left: 10px !important;
      color: #121212;
      font-weight: bold;
    }
  }

  .bubble {
    position: relative;
    padding: 0px;
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: $price-promise-card-bg-color transparent;
      border-width: 20px 15px 0;
      bottom: -20px;
      left: 11px;
    }
  }

  .price-promise-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 9px;
    left: 9px;
    & > svg {
      fill: white;
    }
  }

  .price-promise-card {
    background-color: $price-promise-card-bg-color;
    position: relative;
    width: auto;
    border-radius: $borderRadius;
    margin-top: $marginSize;
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;
    color: #fff;
    padding: 0.5em 0.1em 0.2em 0;

    .bubble {
      padding-left: 2.5em;
      & h2.heading {
        font-size: 18px;
        color: #fff;
        margin-bottom: 0.1em;
      }

      & div.content {
        font-size: 15px;
      }
    }
  }

  & label.toggle-label {
    font-weight: normal;
    & a {
      padding-left: 0.8em;
      & i {
        padding-left: 0.2em;
      }
    }
  }

  & .detail-buttons {
    & a.btn,
    & button {
      border-radius: 0;
      border-right: 2px solid $colorHistoryCardBg;
      margin-bottom: 2px;
      padding: 10px 14px;
      text-align: left;
      &:first-child {
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }

      & > i.fa {
        margin-right: 5px;
      }
    }
  }

  #docsTabs {
    width: 100%;
    ul.nav {
      $active-color: #2c60ae;
      margin-left: $marginSize;
      border-bottom: 2px solid $active-color;
      & > li {
        margin-left: 5px;
        margin-top: 0.3rem;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        background-color: #d5dfef;

        &:hover {
          background-color: $button-outline-bg-hover-color;
        }

        & > a {
          display: inline-block;
          color: $active-color;
          text-transform: uppercase;
          font-size: 14px;
          padding: 8px 22px 6px 22px; // different top and bottom padding due to Chrome having a weird bottom gap between the li and anchor.
          &:hover {
            color: $button-outline-fg-hover-color;
          }
        }

        &.active {
          background-color: $active-color;
          & > a {
            color: white;
          }

          &:hover {
            background-color: $button-primary-bg-hover-color;
          }
        }

        & > a#docsTabs-tab-notifications {
          & .notifications-badge-wrapper {
            display: inline-block;
            width: 1.2em;
            padding: 0 1em 0 1em;
            & .notifications-badge {
              padding: 5px 10px;
              background: rgb(141, 198, 63);
              border-radius: 50%;
              font-weight: bold;
            }
          }
        }

        & > #docsTabs-tab-administration {
          & i.fa-cog {
            font-size: 1.59em;
            vertical-align: middle;
          }
        }
      }
    }

    .tab-content {
      padding-left: $marginSize;
      padding-right: $marginSize;
      & .tab-pane > .card,
      & .tab-pane > p {
        margin-top: $marginSize;
      }
    }

    #docsTabs-pane-notifications {
      & div.card.container.quotes-desktop.desktop-view {
        padding: 1.8em;
      }
    }
  }

  .policy-documents {
    &.mobile-cards {
      display: inherit;
      @media (min-width: $screenMdMinWd) {
        display: none;
      }

      & div.row > .btn-toolbar > .btn {
        margin-right: $marginSize;
      }
    }

    &.cards {
      & .card {
        border: 0;
        padding: $standardPadding $standardPadding 0 $standardPadding;
        border-radius: $borderRadius;
        background-color: $colorCardBg;
        padding-top: $marginSize;
        margin-bottom: $marginSize;
        margin-left: 0;
        margin-right: auto;

        &.quotes-desktop,
        &.quotes-mobile {
          padding-bottom: math.div($marginSize, 2);
        }

        & .notification {
          max-width: 100%;
          width: 100%;
          padding: 0.5em;
          & label {
            display: block;
            white-space: pre-wrap;
          }
          & span {
            white-space: pre-wrap;
          }
        }

        & .card.history {
          background-color: $colorHistoryCardBg;
          padding: 0;
          & > .row {
            margin-left: 0;
            margin-right: 0;
          }

          & .details {
            padding-left: 8px;
            padding-top: 7px;
            & .date {
              @include font-body;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              display: block;
            }

            & .description {
              @include font-body;
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              display: block;
            }

            & .period {
              margin-top: 11px;
              font-size: 14px;
              display: block;
            }
          }

          & .buttons {
            padding: 0;
            & button,
            & a.btn {
              color: white;
              font-size: 14px;
              border: none;
              height: $buttonHeight;
            }
          }

          & .buttons.code-RNW button {
            background-color: #9bb4be;
          }

          & .buttons.code-MTA button {
            background-color: rgba(89, 114, 125, 0.702);
          }

          & .buttons.code-CN button {
            background-color: rgba(89, 115, 126, 0.7);
          }

          & .buttons.code-NB button {
            background-color: rgba(77, 104, 115, 1);
          }

          & .buttons.code-RNW,
          & .buttons.code-MTA,
          & .buttons.code-NB {
            & button:active,
            & button:hover {
              color: $colorButtonActiveFg !important;
              background-color: $colorButtonActiveBg !important;
              border: none;
            }
          }
        }

        & .row.av-expandable {
          text-transform: uppercase;
          background-color: $colorButtonPrimaryBg;
          border-bottom-left-radius: $borderRadius;
          border-bottom-right-radius: $borderRadius;

          &:hover,
          &:active {
            background-color: $colorButtonActiveBg !important;
          }

          & > div {
            color: white;
            font-size: 13px;
            height: $buttonHeight;
            padding-top: 9px;

            &:hover,
            &:active {
              color: $colorButtonActiveFg !important;
            }
          }
        }

        & > .row:last-child {
          border-bottom: 0px;
        }

        & div.label-value:last-child {
          span {
            vertical-align: top;
          }
        }

        & div.label-value {
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10px;
          font-size: 16px;
          & > .document.policy-reference,
          & > .quote-price {
            &,
            & > * {
              @include font-headings;
              font-size: 28px;
            }
          }

          & > .quote-price,
          & > .quote-price-label {
            & > span > sup {
              font-size: 14px;
              top: -0.6rem;
            }
          }

          & > label,
          & > span {
            white-space: nowrap;
          }

          & > label {
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0;
            padding-right: 8px;
          }

          & > span {
            padding-left: 7px;
            display: inline-block;
          }
        }

        // only relevant for desktop atm.
        & .btn-toolbar {
          margin-top: 10px;
          margin-left: 0;
          & .btn {
            margin-bottom: $standardPadding;
            & i {
              float: left;
              margin-top: 3px;
              margin-left: 6px;
              margin-right: $standardPadding;
            }
          }
        }
      }
    }

    .av-expandable {
      cursor: pointer;
    }
  }

  .claim-card-title {
    padding: 0.5em 0.1em 0.1em 0.5em;
  }

  #renew-policy-warning-card {
    background-color: $colorWarning;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    padding-top: 8px;
    max-width: 710px;
    & > .row {
      & > .col {
        display: flex;
        & > i.fa {
          font-size: 2rem;
          margin-top: 5px;
          margin-left: 0px;
          color: white;
          margin-bottom: 5px;
        }

        & > p {
          font-weight: lighter;
          margin-left: 15px;
          margin-bottom: 8px;
          color: white;
        }
      }
    }
  }

  #renew-policy-information-card {
    span {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 0;
      padding-right: 8px;
    }

    > ul {
      font-size: 14px;
      list-style: none;
      margin-top: 0.5em;
      padding: 0;

      & > li {
        display: flex;
        align-items: baseline;

        & > p {
          margin-bottom: 0.5em;
        }

        .fa {
          padding-right: 0.3em;
        }

        .fa-check {
          color: $button-color;
        }

        .fa-exclamation-triangle {
          color: $colorWarning;
        }
      }
    }
  }

  #docsTabs-pane-claims {
    .btn {
      max-width: 11em;
    }
    .claim-sub-title {
      font-weight: bold;
    }
  }

  @media (max-width: 991px) {
    .av-toggle-button {
      & label.toggle-label {
        padding-left: 0px !important;
        display: table;
        font-size: 12px !important;
      }
    }

    & label.toggle-label {
      white-space: normal;
    }
  }

  .modal-dialog {
    margin: 10% auto !important;
    .modal-content {
      margin: 10% auto;
      border-radius: 0 !important;
      box-shadow: none !important;
      #opt-in-modal-content {
        & > div {
          font-size: 14px;
          display: flex;
          align-items: center;
          padding: 0.3em 0 0 1em;
          & > i.fa {
            font-size: 1.6rem;
            color: rgb(39, 100, 174);
            padding-right: 0.2em;
          }
        }
      }
    }
  }

  @media (max-width: #{$screenMdMinWdRaw - 1}px) {
    @import 'mobile-view';
  }

  @media (min-width: $screenMdMinWd) {
    @import 'desktop-view';
  }

  @import 'headersAndFooters';
  @import 'style-guide';
  @import 'font-awesome';
  @import 'bootstrap-3-styles';
}
