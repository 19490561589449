.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  // We need this because we are using bootstrap 4 with react-bootstrap 0.32.4 (bootstrap 3).
  &.fade:not(.show) {
    opacity: 1;
  }

  &.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -o-transform 0.3s ease-out;
  }

  &.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;

  .modal-header {
    &:before,
    &:after {
      display: table;
      content: ' ';
    }
  }

  .modal-footer {
    &:before,
    &:after {
      display: table;
      content: ' ';
    }
  }

  .modal-header:after,
  .modal-footer:after {
    clear: both;
  }
  .modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    outline: 0;

    .modal-header {
      padding: 15px;
      border-bottom: 1px solid #e5e5e5;

      .close {
        margin-top: -2px;
      }
    }

    .modal-title {
      margin: 0;
      line-height: 1.42857143;
    }

    .modal-body {
      position: relative;
      padding: 15px;
    }

    .modal-footer {
      padding: 15px;
      text-align: right;
      border-top: 1px solid #e5e5e5;
      justify-content: center;

      .btn {
        margin-bottom: 0;
      }

      .btn + .btn {
        margin-left: 5px;
      }

      .btn-group .btn + .btn {
        margin-bottom: 0 !important;
        margin-left: -1px;
      }

      .btn-block + .btn-block {
        margin-left: 0;
      }
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;

  &.fade {
    filter: alpha(opacity = 0);
    opacity: 0;
  }

  &.in {
    filter: alpha(opacity = 50);
    opacity: 0.5;
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
