.desktop-view {
  display: inherit;
}

.mobile-view {
  display: none;
}

.container.alert {
  padding-left: 0;
  padding-right: 0;
}

.row {
  .container.detail,
  .container.history {
    padding-left: 0;
    padding-right: 0;
    & > .row {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.row .container.detail > .row > div {
  padding-left: 0;
  padding-right: 0;
}

.policy-documents.cards .card.container {
  margin-right: 0px;

  .row {
    .container.detail {
      .label-value {
        label {
          padding-left: 0;
        }
      }

      .buttons {
        padding-left: 0;
        position: relative;
      }
    }

    .card.container.history {
      .description,
      .period {
        margin-top: 8px;
        display: inline-block;
        width: 50%;
      }

      .description {
        @include font-body;
        font-weight: bold;
        font-size: 14px;
      }

      .period {
        font-size: 14px;
        text-align: right;
        padding-right: 5%;
      }

      & .buttons {
        & > button,
        & > a.btn {
          width: 100%;
          border: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          text-align: left;
          border-top-right-radius: $borderRadius;
          border-bottom-right-radius: $borderRadius;
        }
      }

      & .detail-buttons {
        & a.btn,
        & button {
          border-radius: 0;
          &:first-child {
            border-top-right-radius: $borderRadius;
          }

          &:last-child {
            border-bottom-right-radius: $borderRadius;
          }
        }
      }
    }
  }
}
