.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  overflow-x: hidden;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}
