@import './node_modules/@avantia/style-guide/_variables.scss';

$screenSmMinWd: 576px;
$screenMdMinWdRaw: 768;
$screenMdMinWd: #{$screenMdMinWdRaw}px;
$screenLgMinWd: 992px;
$screenXLMinWd: 1200px;
$marginSize: 15px;
$borderRadius: 0;

$colorBodyBg: $background-color;
$colorButtonPrimaryBg: $button-primary-bg-color;
$colorButtonPrimaryFg: $button-primary-fg-color;
$colorButtonSecondaryBg: white;
$colorButtonSecondaryFg: $colorButtonPrimaryBg;
$colorButtonActiveBg: $button-primary-bg-active-color;
$colorButtonActiveFg: $button-outline-fg-active-color;

$colorCardBg: $card-bg-color;
$colorHistoryCardBg: $card-bg-color;
$colorWarning: rgb(239, 106, 0);
$standardPadding: 16px;
$buttonHeight: 40px;
