@import 'common-variables';

@media (max-width: $screenMdMinWd) {
  & {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: $marginSize;
  }
}

h1,
h2,
h3,
h4 {
  @include font-headings;
}

h1 {
  font-size: 20px;
  margin-bottom: 1rem;
}

h2 {
  font-size: 16px;
  margin-bottom: 1rem;
}

main.container > div.container > div.row > h1 {
  margin-bottom: 1.6rem;
  padding-left: 1rem;
}

a {
  color: $colorButtonPrimaryBg;
  &:active,
  &:hover {
    color: $colorButtonActiveBg;
    text-decoration: none;
  }
}

input,
.btn,
.card,
.alert {
  border-radius: $borderRadius;
}

.btn,
.card,
.alert {
  border-width: 0;
}

.btn {
  white-space: nowrap;

  &.btn-primary {
    color: $button-primary-fg-color;
    border-color: $button-primary-bg-color;
    background-color: $button-primary-bg-color;
    &:hover {
      color: $button-primary-fg-hover-color;
      border-color: $button-primary-bg-hover-color;
      background-color: $button-primary-bg-hover-color;
    }

    &:active {
      color: $button-primary-fg-active-color;
      border-color: $button-primary-bg-active-color;
      background-color: $button-primary-bg-active-color;
    }
  }

  &.btn-info {
    border-width: 1px;
    color: $button-primary-bg-color;
    border-color: $button-outline-fg-color;
    background-color: $button-outline-bg-color;
    &:hover {
      color: $button-outline-fg-hover-color;
      border-color: $button-outline-bg-hover-color;
      background-color: $button-outline-bg-hover-color;
    }

    &:active {
      color: $button-outline-fg-active-color;
      border-color: $button-outline-bg-active-color;
      background-color: $button-outline-bg-active-color;
    }
  }

  &.btn-link {
    &:active,
    &:hover {
      color: $colorButtonActiveBg !important;
      background-color: $colorBodyBg !important;
      text-decoration: none;
    }
  }
}

.margin-plus1 {
  $margin-left: $marginSize;
}

.margin-minus1 {
  $margin-left: -$marginSize;
}

#welcome-message {
  margin-left: $marginSize;
  margin-right: $marginSize;
}

#header {
  padding-top: 1em;
  padding-bottom: 0.5em;
  & section.header {
    margin-left: 0;
    margin-right: 0;
  }
}

.salutation-header {
  margin-left: $marginSize;
  margin-right: $marginSize;
  margin-top: 0rem;
  margin-bottom: 1.2rem;
}

#auth-in-progress-screen {
  margin-left: 0;
  margin-right: -$marginSize;
  padding-right: 0;
}

div.alert {
  margin-left: 0;
  margin-right: 0;
  &.row {
    margin-top: $marginSize;
    & span {
      display: block;
      width: 100%;
      margin-bottom: 0.25em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.logo {
  // Native size is 7539 x 2387 (ratio w/h is 3.15835777126)
  background-size: cover;
  @media (max-width: $screenMdMinWd) {
    height: 25px;
    width: 79px;
  }

  @media (min-width: $screenMdMinWd) {
    height: 32px;
    width: 101px;
  }
}

.welcome-card,
.copyright-card {
  text-align: left;
  width: 100%;
  h1 {
    font-size: 1.8rem;
    @media (max-width: $screenMdMinWd) {
      & {
        margin-top: 0.2em;
      }
    }
  }

  div.footer-links div.row {
    text-align: left;
    display: block;
  }
}

div.copyright-card {
  margin-left: $marginSize;
  margin-right: $marginSize;
  font-size: 10px;
  @media (max-width: $screenMdMinWd) {
    & {
      margin: -$marginSize 0 0 0;
    }

    & > .row > .text-center {
      width: 100%;
    }
  }

  & #DigiCertClickID_uhsiDmtF {
    max-width: 40px;
    margin-top: 6rem;
    margin-bottom: 2.5rem;
    margin-left: 0;
    padding-top: 1rem;
    & div > img {
      width: 70px !important;
    }
  }

  & > div.footer-links > div.row {
    margin-left: 0px;
  }

  & > div.row > div.col-1 {
    padding-left: 0;
  }

  & > p {
    margin-bottom: 0.6rem;
    @media (max-width: $screenMdMinWd) {
      text-align: left;
      margin-bottom: 1.5em;
      &.copyright-section {
        margin-bottom: 0;
        span {
          display: inline-block;
        }
        & .spacer {
          display: inline-block;
        }
      }
    }
  }

  div.footer-links {
    margin-bottom: 1rem;
    @media (max-width: $screenMdMinWd) {
      margin-right: 1em;
      margin-top: 1.5em;
    }
  }
}

span.spacer {
  margin: 0 0.5em 0 0.5em;
  @media (max-width: $screenMdMinWd) {
    & .spacer {
      margin: 0 0.3em 0 0.3em;
    }
  }
}

p.message {
  &.home-link {
    @media (max-width: $screenMdMinWd) {
      text-align: center;
    }
  }
}

div.notification-card.cookie-card {
  margin: 0em;
  margin-top: -$marginSize;
  margin-bottom: $marginSize;
  padding: 1em;
  position: relative;
  @media (max-width: $screenMdMinWd) {
    margin: 1em 0 0 0;
    padding: 0 0 0.5em 0.5em;
  }

  background-color: #f0f0f0;

  p {
    margin-bottom: 0;
  }

  button.btn-x {
    position: absolute;
    right: 1em;
    top: 1em;
    @media (max-width: $screenMdMinWd) {
      right: 0em;
      top: -0.4em;
    }
    & > i:before {
      content: '\f04a';
      font-family: 'HpIcons';
      font-size: 1em;
      font-style: normal;
    }
  }
}

div.table-width {
  padding-left: 0;
  padding-right: 0;
}

#logOff {
  margin-left: 0;
  padding: 0;
  & .btn-link {
    padding: 0;
  }
}
