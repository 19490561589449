.model-error {
  color: red;
  font-size: 12px;
}

.model-warning {
  color: orange;
}

a {
  cursor: pointer;
}

a > * {
  cursor: pointer;
}

.form-inline > .form-group .editable-buttons {
  & button {
    &.editable-submit,
    &.editable-cancel {
      & > i::before {
        font-size: 1.1em;
      }
    }

    &.editable-cancel {
      position: relative;
      top: -8px;
    }
  }
}

.logo-updated {
  padding-top: 0.8rem;
  padding-left: 0.8rem;
  padding-bottom: 0.8rem;
}
