.div-block {
    margin-top: 5px;
    margin-left: 2px;
    margin-right: 2px;
}

.form-control.currency-form-control {
    display: inline;
    width: 90%;
}

.dont-show {
    visibility: hidden;
}

.radiusRound-form-control.form-control {
    border-radius: '20px';
}

.HRAmountVariables {
    margin-top: 10px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

