$fontPath: $assetsPath + 'fonts/';

@font-face {
  font-family: 'FontAwesome';
  src: url($fontPath + 'fontawesome-webfont.eot?v=4.7.0');
  src: url($fontPath + 'fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
    url($fontPath + 'fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
    url($fontPath + 'fontawesome-webfont.woff?v=4.7.0') format('woff'),
    url($fontPath + 'fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
    url($fontPath + 'fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HpIcons';
  src: url($fontPath + 'icons.eot');
  src: url($fontPath + 'icons.eot?#iefix') format('embedded-opentype'), url($fontPath + 'icons.woff2') format('woff2'),
    url($fontPath + 'icons.woff') format('woff'), url($fontPath + 'icons.ttf') format('truetype'),
    url($fontPath + 'icons.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
