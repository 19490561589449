@use 'sass:math';

.desktop-only {
  display: none;
}

.desktop-view {
  display: none;
}

.container.alert {
  padding-left: 0;
  padding-right: 0;
}

.btn-action.btn-info {
  margin-bottom: $marginSize;
}

& .detail-buttons {
  & a.btn,
  & button {
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.price-promise-card {
  width: 100%;
  padding: 0.3em;
  .bubble {
    padding-left: 2em;
  }
}

.policy-documents.cards .card.container {
  &.quotes-mobile {
    padding-bottom: 0.5rem;
  }

  .warning-card-container {
    & > .col-12 {
      padding: 0;
      #renew-policy-warning-card {
        padding: 8px 16px;
        & > .row {
          & > .col-12 {
            align-items: center;
            padding: 0;
            & > i {
              padding: 5px 12px 5px 0;
              margin: 0;
            }
            & > p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .av-toggle-button {
    & .btn {
      //width: 39px !important;
      & > span {
        & > p {
          font-size: 10px;
          margin: 1px 0 0 17px;
        }
      }
      &.active {
        & > span {
          & > p {
            margin: 1px 19px 0 0;
          }
        }
      }
    }

    & label {
      padding-left: 5px;
      font-size: 14px;
    }
  }

  & > .row {
    margin-left: 0;
    margin-right: 0;
    &.av-expandable {
      margin-left: -$marginSize;
      margin-right: -$marginSize;
    }

    & div.label-value {
      label {
        padding-left: 0;
      }

      & > span {
        padding-left: 0;
        padding-right: 0;
      }
    }

    & .btn.saved-quote {
      width: auto;
    }
  }

  & .react-slidedown > .row > div > .container.history {
    & > .row > .card.container.history {
      padding: $marginSize;
      padding-top: math.div($marginSize, 2);
      padding-bottom: 0;
      & .description,
      .period {
        width: 100%;
        display: block;
      }

      & .button-row {
        margin-top: 0.8rem;
        margin-left: -$marginSize;
        margin-right: -$marginSize;
        & > div:first-of-type > div > button {
          border-bottom-left-radius: $borderRadius;
          border-right: 1px solid $colorHistoryCardBg;
        }

        & > div:last-of-type > div > button {
          border-bottom-right-radius: $borderRadius;
          border-left: 1px solid $colorHistoryCardBg;
        }
      }

      & .buttons {
        padding: 0;
        & div {
          & > button,
          & > a.btn {
            color: white;
            font-size: 14px;
            text-align: center;
            border: none;
            padding-left: 11px;
            height: $buttonHeight;
            width: 100%;
            margin-bottom: 2px;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.modal-content {
  width: 30em;
  #opt-in-modal-content {
    & > div {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0.3em 0 0 1em;
      & > i.fa {
        font-size: 1.6rem;
        color: #2764ae;
        padding-right: 0.2em;
      }
    }
  }
}

.copyright-card {
  padding-left: $marginSize;
  padding-right: $marginSize;
}

@media (max-width: 530px) {
  .label-value {
    & > label,
    & > span {
      display: block;
      width: 100%;
      font-weight: bold;
    }
  }
  .modal-content {
    width: 18em;
  }
}

@media (min-width: 525px) {
  .row.btns > div.col-xs-12 {
    //width: 50%;
    &:first-of-type {
      padding-right: 8px !important;
    }
  }
}

@media (min-width: $screenSmMinWd) {
  .av-toggle-button {
    & label.toggle-label {
      padding-left: 0px !important;
      display: table;
      font-size: 12px;
    }
  }
}

@media (max-width: $screenMdMinWd) {
  .av-toggle-button {
    & label.toggle-label {
      padding-left: 0px !important;
      display: table;
      font-size: 12px !important;
    }
  }
}

@media (max-width: 575px) {
  .av-toggle-button {
    & label.toggle-label {
      padding-left: 0px !important;
      display: table;
      font-size: 12px !important;
    }
  }
}

@media (max-width: 992px) {
  .av-toggle-button {
    & label.toggle-label {
      font-size: 12px !important;
    }
  }
}
