.footer {
  /* display: none!important; */
  visibility: hidden !important;
}

.imageBorderOutline {
  border: 1px rgb(188, 188, 188) solid;
  padding: 10px;
}

.divrowtopspace {
  margin-top: 20px;
}

.divcolbottomspace {
  margin-bottom: 5px;
}

.selected-preference {
  background: rgb(44, 96, 174);
  color: white;
  display: block;
}

.imageBorderOutline.selected-preference:hover {
  background-color: #163057;
}

.imageBorderOutline:hover {
  background-color: rgba(27, 135, 230, 75%);
}

.div-text-align-center {
  text-align: center;
}

.selected-visble-selection {
  display: inline-block;
  text-align: center;
}

.selected-notvisble-selection {
  display: none;
  text-align: center;
}

.error-email-address {
  color: #ff0000;
  font-size: 0.7em !important;
}
.info-dvfont {
  font-size: 0.7em;
  padding-top: 0.5em;
}

.supplement-dvfont {
  padding-top: 10px;
  font-size: 0.7em;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  visibility: hidden;
}

.removeMargin {
  margin-top: -70px;
}

@media (max-width: 767px) {
  .imageBorderOutline:hover {
    background: white;
  }
}
