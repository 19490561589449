// Toggle button brought in from Style Guide
@mixin drop-shadow {
  box-shadow: none; // 0px 3px 6px $drop-shadow-color;
}

@mixin toggle-button-control($slotHeight, $slotWidth, $handleRadius, $fontSize) {
  $toggleBackgroundColour: $card-main-bg-color;
  $toggleHandleColour: #62b5f6;
  $handleOffset: math.div($handleRadius * 2 - $slotHeight, 2);
  $handleTop: -($handleRadius - math.div($slotHeight, 2));
  $slotMovement: $slotWidth - $handleRadius * 2;

  .av-toggle-button {
    padding: 0.4em 0;

    & > div {
      & > label:first-child {
        font-size: $fontSize;
        line-height: $line-height-std;
        padding-right: 1.5rem;
        text-transform: uppercase;
      }

      & > button {
        border-radius: #{$slotHeight}px;
        border: none;
        height: #{$slotHeight}px;
        padding: 0;
        position: relative;
        width: #{$slotWidth}px;
        transition: opacity 0.25s;
        background-color: $toggleBackgroundColour;

        & + label {
          margin-top: 0.5em;
          margin-bottom: 0;
        }

        &:focus,
        &:focus.active {
          @include drop-shadow;
        }

        & > .av-toggle-button-handle {
          position: absolute;
          background: $toggleHandleColour;
          border-radius: #{$handleRadius}px;
          border: none;
          width: #{$handleRadius * 2}px;
          height: #{$handleRadius * 2}px;
          left: -#{$handleOffset}px;
          top: #{$handleTop}px;
          transition: left 0.25s;
        }

        &.active {
          transition: background-color 0.25s;
          & > .av-toggle-button-handle {
            left: #{$slotMovement + $handleOffset}px;
          }
        }
        & + label {
          color: #cccccc;
        }

        &:hover {
          & .av-toggle-button-handle {
            background-color: $button-color;
          }
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      & > div {
        cursor: not-allowed;
        & > button {
          cursor: not-allowed;
          & > .av-toggle-button-handle {
            cursor: not-allowed;
            background-color: $input-disabled-color;
          }
        }
      }
    }
  }
}

// Our modals don't have borders.
.modal {
  & .modal-header {
    border-bottom: none !important;
  }
  & .modal-footer {
    border-top: none !important;
    padding: 0px !important;
    padding-bottom: 15px !important;
  }
}

@media (min-width: $media-min-width-desktop) {
  @include toggle-button-control(21, 50, 14, 13px);
  //@include toggle-button-control(17, 32, 7, 13px);
}

@media (max-width: $media-max-width-mobile) {
  @include toggle-button-control(16, 36, 11, 12px);
  //@include toggle-button-control(17, 32, 7, 13px);
}
