@import 'style/_common-variables.scss';

$placeholderColor: #8a8a8a;

input {
  &:-webkit-input-placeholder,
  &:-webkit-input-placeholder-shown,
  &::-webkit-input-placeholder,
  &::-webkit-input-placeholder-shown {
    color: $placeholderColor !important;
  }

  &:-ms-input-placeholder,
  &:-ms-input-placeholder-shown,
  &::-ms-input-placeholder,
  &::-ms-input-placeholder-shown {
    color: $placeholderColor !important;
  }

  &:-moz-placeholder,
  &:-moz-placeholder-shown,
  &::-moz-placeholder,
  &::-moz-placeholder-shown {
    color: $placeholderColor !important;
  }

  &::placeholder {
    color: $placeholderColor !important;
  }
}

div.login-container {
  & h1 {
    margin-left: $marginSize;
    font-size: 24px;
  }

  & .container.card {
    padding-top: $marginSize;
    padding-bottom: 0.35rem;
    & .row {
      margin-left: 0;
      margin-right: 0;
      & h2 {
        font-size: 20px;
      }

      & > p {
        margin-bottom: 1.5rem;
        max-width: 700px;
      }

      & label {
        font-weight: bold;
      }

      &.input-controls > .col {
        padding-left: 0;
        button#get-access-link {
          margin-top: 0.6rem;
          font-size: 21px;
          font-family: 'Open Sans', sans-serif;
          font-stretch: condensed;
          text-transform: uppercase;
          padding: 18px 24px;
          height: auto;
        }

        &.col {
          & > button#get-access-link {
            max-width: 250px;
          }

          & #emailAddress {
            max-width: 350px;
          }

          & #postcode {
            max-width: 200px;
          }
        }
      }
    }
  }
}
